import React, { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'
import { useTranslation } from 'react-i18next'

// oggetto base per il prodotto
// ha i parametri di default per quando si stanno scaricando i dati
const baseProductObject = {
  name: 'Fetching data...',
  product_information: {
    description: "We're fetching the needed informations. Please wait",
    img: {
      profile: '',
      background: ''
    }
  }
}

// ha i parametri di default per quando non si hanno dati
const baseCompanyObject = {
  name: 'Company not found',
  description: "We didn't found the company you are looking for",
  additionalData: {}
}

// Stato iniziale
const initialState = {
  product: baseProductObject,
  company: baseCompanyObject,
  expirationDate: '',
  sectionActive: 1,
  anchorMenu: null,
  currLot: {},
  urlHasLot: false,
  openCert: false,
  selectedCert: {},
  openTrackDoc: false,
  selectedTrackDoc: {},
  openTrackDesc: false,
  selectedTrackDesc: {},
  lotNotFound: false,
  lotNumber: '',
  informationFound: true,
  companyLoaded: false
}

// Hook che traduce lo stato iniziale nella lingua impostata nel browser
const useTranslatedState = () => {
  const { t } = useTranslation('appState')
  const transaltedState = initialState
  transaltedState.product.name = t('product.name')
  transaltedState.product.product_information.description = t('product.description')
  transaltedState.company.name = t('company.name')
  transaltedState.company.description = t('company.description')

  return transaltedState
}

// Creazione del context per l'app
export const AppContext = createContext(initialState)

// Componente Provider con cui wrappare tutta l'app
export const AppProvider = ({ children }) => {
  // stato tradotto
  const translatedState = useTranslatedState()
  const [state, dispatch] = useReducer(AppReducer, translatedState)

  // return the actual component
  return <AppContext.Provider value={{
    state,
    dispatch
  }}>
    {children}
  </AppContext.Provider>
}
