import React, { useContext } from 'react'
// import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { Grid, Container } from '@material-ui/core'
import './style.css'

const Footer = (props) => {
  const { state } = useContext(AppContext)
  const { company } = state
  // const { t } = useTranslation('menu')
  console.log(company)
  return (
    <>
      <Grid container className="footerBar">
        <Container>
          <Grid item xs={12}>
            <h5 style={{ textAlign: 'center' }}>{company.name}, {company.address && company.address.city}, {company.address && company.address.street} | P.Iva: {company.taxId && company.taxId} </h5>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

export default Footer
