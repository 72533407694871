import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Input from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { getLots, populateLotData } from '../../actions/AppActions'

const useStyles = makeStyles(presentationStyle)

const TraceabilityInput = () => {
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityInput')

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { lotNotFound, product, lotNumber } = state

  // temporary gtin
  const productUuid = product.uuid

  const searchLot = async () => {
    if (!lotNumber) {
      return alert('Non è stato inserito nessun lotto')
    }

    try {
      // resetto la variabile che dice che non ha trovato il lotto del lotto
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: false
      })
      let lots = await getLots(productUuid, lotNumber)

      // Se il lotto esiste, allora popolo i campi e lo mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)

        dispatch({
          type: 'SET_LOT',
          payload: lots
        })

        dispatch({
          type: 'SET_EXPIRATION_DATE',
          payload: expirationDate
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // funzione che simula il click su un bottone
  const simulateClick = (e) => {
    dispatch({
      type: 'SET_LOT_NOT_FOUND',
      payload: false
    })
    if (e.keyCode === 13) {
      searchLot()
    }
  }

  // funzione che cambia il lotto mostrato
  const changeLotNumber = (event) => {
    const lot = event.target.value
    dispatch({
      type: 'SET_LOT_NUMBER',
      payload: lot
    })
  }

  return <GridContainer display="flex" justify="center" alignItems="center" style={{ marginBottom: 50 }}>
    <GridItem xs={12} md={3}></GridItem>
    <GridItem xs={12} md={9} style={{ display: 'flex', justifyContent: 'center' }}>
      <GridItem xs={12} md={8} style={{ padding: 15, background: '#fafafa', boxShadow: '0 0 4px 1px #D6D6D6' }}>
        <Box component="div">
          <Box component="p" style={{ padding: isSmall ? 8 : '' }}><b>{t('message')}</b></Box>
          {lotNotFound === true ? <Box component="div" boxShadow={1} style={{ margin: isSmall ? 8 : '' }} className={classes.warningAlert}>
            <Typography variant="button">{t('warning')}</Typography>
            <Typography component="p" variant="body2">
              {lotNumber !== '' ? t('notFoundMessage', { lotNumber }) : t('notFoundUrlMessage')}
            </Typography>
          </Box> : ''
          }
          <GridContainer direction="row" justify="center" alignItems="center" spacing={2} style={{ width: isSmall ? '100%' : '', margin: isSmall ? 0 : '' }}>
            <GridItem xs={12} md={12} zeroMinWidth>
              <Input value={lotNumber} formControlProps={{ fullWidth: true }} inputProps={{ onKeyUp: (e) => simulateClick(e), placeholder: t('inputPlaceholder'), onChange: (event) => changeLotNumber(event) }} />
            </GridItem>
            <GridItem xs={12} md={12} zeroMinWidth>
              <Button onClick={() => searchLot()} style={{ backgroundColor: '#09A963', width: '100%' }} >{t('searchButton')}</Button>
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
    </GridItem>
  </GridContainer>
}

export default TraceabilityInput
