import React from 'react'
import './App.css'
import configuration from './configuration'
import PresentationPage from './views/PresentationPage/PresentationPage.js'
import OpentimestampsPage from './views/Opentimestamps/Opentimestamps.js'
import NotFound from 'components/NotFound/NotFound'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppProvider } from 'context/AppState'
import preval from 'preval.macro'

import './i18n'

const matomo = () => {
  console.log('attivo matomo')
  var _paq = window._paq || []
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['setDocumentTitle', document.domain + '/' + document.title])
  // _paq.push(['setDomains', ['*.']])
  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])
  // (function () {
  //   var u = 'https://analytics.trusty.id/'
  //   _paq.push(['setTrackerUrl', u + 'matomo.php'])
  //   _paq.push(['setSiteId', '2'])
  //   var d = document; var g = d.createElement('script'); var s = d.getElementsByTagName('script')[0]
  //   g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s)
  // })()
}

function App () {
  // controllo se attivare matomo o no
  if (configuration.environment === 'production') {
    matomo()
  }

  // Stampiamo in console la data dell'ultima build dell'app
  const buildDate = preval`
const moment = require('moment')
const buildTimestamp = moment().format('DD/MM/YYYY HH:mm:ss')
module.exports = buildTimestamp
`

  console.log(buildDate)

  return (
    <Router>
      <AppProvider>
        <Switch>
          <Route path='/gtin/:prod/lot/:lot' exact component={PresentationPage} />
          <Route path='/gtin/:prod' exact component={PresentationPage} />
          <Route path='/opentimestamps' exact component={OpentimestampsPage} />
          <Route component={NotFound} />
        </Switch>
      </AppProvider>
    </Router>
  )
}

export default App
