const baseRect = {
  borderLeftStyle: 'solid',
  borderLeftWidth: '10px'
}

const opentimestampsStyle = theme => ({
  hPaddingZero: {
    padding: 'auto 0'
  },
  root: {
    background: 'linear-gradient(45deg, #09A963 40%, #3BD982 100%)',
    color: 'white',
    textAlign: 'center'
  },
  content: {
    backgroundColor: 'white',
    color: '#333'
  },
  digestBox: {
    border: '1px solid #fafafa',
    backgroundColor: '#00663A',
    borderRadius: '4px',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      overflowWrap: 'break-word',
      wordWrap: 'break-word'
    },
    [theme.breakpoints.only('sm')]: {
      width: '80%',
      overflowWrap: 'break-word',
      wordWrap: 'break-word'
    }
  },
  table: {
    width: '850px',
    padding: '10px',
    [theme.breakpoints.only('xs')]: {
      width: '300px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '350px'
    }
  },
  tableBody: {
    width: '100%',
    borderTop: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0'
  },
  label: {
    width: '230px',
    borderRight: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: {
      width: '30px'
    }
  },
  noPadding: {
    [theme.breakpoints.only('xs')]: {
      padding: '8px'
    }
  },
  valueBox: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    width: '600px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    [theme.breakpoints.only('xs')]: {
      width: '150px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '300px'
    }
  },
  attestationBox: {
    width: '600px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    [theme.breakpoints.only('xs')]: {
      width: '150px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '300px'
    }
  },
  textLight: {
    fontWeight: '300'
  },
  textNormal: {
    fontWeight: '500'
  },
  highlightText: {
    color: '#2db253'
  },
  forks: {
    ...baseRect,
    borderLeftColor: '#159dfc'
  },
  verify: {
    ...baseRect,
    borderLeftColor: '#2db253'
  },
  neutral: {
    paddingLeft: '20px'
  },
  transactionId: {
    ...baseRect,
    borderLeftColor: '#9c38e1'
  },
  bitcoinBlockMerkleRoot: {
    ...baseRect,
    borderLeftColor: '#ee8f13'
  },
  pathBox: {
    borderRadius: '4px 0 0 4px',
    color: 'white',
    backgroundColor: '#646464',
    width: '30px'
  },
  wrapperBox: {
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    width: '600px',
    overflowWrap:
    'break-word',
    wordWrap: 'break-word',
    [theme.breakpoints.only('xs')]: {
      width: '150px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '300px'
    }
  },
  hashBox: {
    borderRadius: '0 4px 4px 0',
    backgroundColor: '#f7f7f7',
    width: '570px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    [theme.breakpoints.only('xs')]: {
      width: '120px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '270px'
    }
  },
  legendWrapper: {
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      flexWrap: 'wrap'
    }
  }
})

export default opentimestampsStyle
