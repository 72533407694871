import React, { useContext } from 'react'
import { useMediaQuery, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Map from 'components/Map/Map'
import { AppContext } from '../../context/AppState'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import Fade from 'react-reveal/Fade'
import './style.css'

const useStyles = makeStyles(presentationStyle)

const CompanyInfo = () => {
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('companyInfo')
  const { state } = useContext(AppContext)
  const { company } = state

  const extractProductionKey = (attributeKey) => {
    let translatedString = ''
    if (attributeKey.includes('productions')) {
      const productionsArray = attributeKey.split(' ')
      const [prodString, ...rest] = productionsArray
      translatedString = [t(prodString), ...rest].join(' ')
    } else {
      translatedString = t(attributeKey)
    }

    return translatedString
  }

  return <GridContainer style={isSmall ? { padding: 0, marginTop: '16px' } : { padding: '80px 0 0 0', position: 'relative', zIndex: 0 }} spacing={4}>
    <GridItem xs={12} md={3}>
      <h3 className="sticky">{t('title')}</h3>
    </GridItem>
    <GridItem xs={12} md={9}>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <GridContainer display="flex" justify="space-between" alignItems="center" className={classes.infoContainer}>
            {/* <GridItem className={'companyImg'} xs={12}>
                <CardMedia component="img" className={isSmall ? classes.cardMediaMobile : classes.cardMedia} image={(company && company.logo && company.logo.cropped ? company.logo.cropped : profile) || <Skeleton duration={0.7} width={100} height={100} /> } />
              </GridItem> */}

            <GridItem className={`${classes.companyCardtext} noPad`} xs={10}>
              <Fade big>
                <h3 className={classes.cardTitle}>{company.name}</h3>
                <ul className="attributesList">
                  <li>

                    <p><span className={classes.boldText}>{`${t('address')}: `}</span>{`${company && company.address && company.address.street}`}</p>
                  </li>
                  <li>
                    <p><span className={classes.boldText}>{`${t('city')}: `}</span>{`${company && company.address && company.address.city}`}</p>
                  </li>

                  {company && company.email
                    ? <li>
                      <p>
                        <span className={classes.boldText}>{`${t('email')}: `}</span>
                        <a style={{ color: 'white', textDecoration: 'underline' }} href={`mailto:${company.email}`} >{company.email}</a>
                      </p>
                    </li>
                    : ''}

                  {company && company.phoneNumber
                    ? <li>
                      <p>
                        <span className={classes.boldText}>{`${t('phoneNumber')}: `}</span>
                        <a style={{ color: 'white', textDecoration: 'underline' }} href={`tel:${company.phoneNumber}`} >{company.phoneNumber}</a>
                      </p>
                    </li>
                    : ''}

                  {company && company.mobileNumber
                    ? <li>
                      <p>
                        <span className={classes.boldText}>{`${t('mobileNumber')}: `}</span>
                        <a style={{ color: 'white', textDecoration: 'underline' }} href={`tel:${company.mobileNumber}`} >{company.mobileNumber}</a>
                      </p>
                    </li>
                    : ''}
                  {company && company.attributes
                    ? company.attributes.map((attribute, index) => <li key={`attribute-${index}`}><p>
                      <span className={classes.boldText}>{`${extractProductionKey(attribute.name)}: `}</span>
                      {attribute.value}
                      {attribute.name === 'surface' ? t('surfaceUnit') : ''}
                    </p>
                    </li>)
                    : ''}
                </ul>
              </Fade>
            </GridItem>
            <GridItem className={classes.companyCardtext} xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {company && company.website
                ? <a target="_blank" rel="noopener noreferrer" href={company.website}>
                  <Tooltip title="Vai al sito">
                    <ArrowForwardIcon style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }} />
                  </Tooltip>
                </a>
                : ''}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={12} style={{ marginBottom: 30 }}>
          <Fade big>
            <h5 className={classes.cardTitleSmall}>{t('description')}</h5>
            {company.description ? (company.description.includes('\n') ? company.description
              .split('\n')
              .map(row => {
                const rand = Math.random()
                return (<p key={`${row} ${rand}`}>{row}</p>)
              }) : company.description
            ) : ''}
          </Fade>
        </GridItem>
        <GridItem xs={12} md={6} style={{ marginBottom: 30 }}>
          <Fade big>
            {company && company.geolocation && company.geolocation.lat && company.geolocation.lng ? <GridItem xs={12} md={12} className={classes.map}>
              <h4 className={classes.cardTitleSmall}>{t('map')}</h4>
              <Map lat={company.geolocation.lat} lng={company.geolocation.lng} />
            </GridItem> : ''}
          </Fade>
        </GridItem>
      </GridContainer>
    </GridItem>
  </GridContainer>
}

export default CompanyInfo
