import React, { useContext } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Box, Typography, Table, TableBody, TableHead, TableRow, TableCell, Link } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import Map from 'components/Map/Map'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import Fade from 'react-reveal/Fade'
import Line from '../Line/Line.js'
import { AppContext } from '../../context/AppState'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(presentationStyle)

// funzione che ritorna il corpo della tabella degli input/output
const inputsOutputsTableDoby = (inputs, outputs, track) => {
  const array = [...Array(!outputs ? inputs.length : inputs.length >= outputs.length ? inputs.length : outputs.length)]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs[i] && inputs[i].lotNumber) {
      newLine[0] = `${inputs[i].inputData.name} (Lot ${track ? track.inputs[i].lotNumber : ''}) ${track ? (track.inputs[i].quantity != null ? ` - ${track.inputs[i].quantity} ${inputs[i].uom}` : '') : ''}`
    }
    if (outputs && outputs[i] && outputs[i].quantity) {
      newLine[1] = `${outputs[i].outputData.name} (Lot ${track.outputs ? track.outputs[i].lotNumber : ''}) ${track ? (track.outputs[i].quantity != null ? ` - ${track.outputs[i].quantity} ${outputs[i].uom}` : '') : ''}`
    }
    if (newLine && newLine.length > 0) {
      finalArray.push(newLine)
    }
  })

  return finalArray.map((elem, i) => {
    return (
      <TableRow key={i}>
        <TableCell align="left">{elem[0]}</TableCell>
        {outputs && <TableCell align="left">{elem[1]}</TableCell>}
      </TableRow>
    )
  })
}

const TraceabilityMobileCard = (props) => {
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityMobileCard')

  const { track, index } = props

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { currLot, company } = state

  // funzione che apre modal documenti
  const handleOpenTrackDoc = (track) => {
    dispatch({
      type: 'SET_TRACK_DOC',
      payload: track
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DOC',
      payload: true
    })
  }

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }

  // funzione che mostra la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex) => {
    const lot = Object.assign({}, currLot)
    const traceability = lot.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, { activeSection: { card: cardIndex, showMap: !activeSection.showMap }, ...track })
    lot.traceability = traceability
    dispatch({
      type: 'SET_LOT',
      payload: lot
    })
  }

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      if (elem.scrollWidth > elem.clientWidth) {
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ width: '100%' }}>
            <Link onClick={() => handleOpenTrackDesc(description, name, imgUrl)} color="primary" style={{ cursor: 'pointer' }}>
              {t('showMore')}
            </Link>
          </Box>
        )
      } else {
        return ''
      }
    }
  }

  return <Fade> <GridItem className="traciabilityContainer" md={6} xs={12} style={isSmall ? { paddingLeft: 0, paddingRight: 0 } : {}}>
    <Card style={{ marginBottom: '10px', marginTop: '10px', paddingBottom: 14, height: 'auto' }}>
      <CardHeader>
        <GridContainer spacing={6} direction="column" alignItems="center" justify="flex-start">
          <GridItem style={{ width: '100%' }} xs={12}>
            {
              track.location && track.location.lat && track.location.lng && track.activeSection.showMap
                ? <div className={classes.imgContainer}>
                  <Map isHeader={true} lat={track.location.lat} lng={track.location.lng} />
                </div>
                : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationFrom[0].lat && track.locationFrom[0].lng &&
                track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0 && track.locationTo[0].lat && track.locationTo[0].lng && track.activeSection.showMap
                  ? <div className={classes.imgContainer}>
                    <Map isHeader={true} zoom={14} positions={[[track.locationFrom[0].lat, track.locationFrom[0].lng], [track.locationTo[0].lat, track.locationTo[0].lng]]} lat={track.locationTo[0].lat} lng={track.locationTo[0].lng} />
                  </div>
                  : <div className={classes.imgContainer} style={track.media.length > 0 ? { backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
                  </div>
            }
          </GridItem>
          <GridItem style={{ marginTop: '-50px', padding: 0, width: '100%', textAlign: 'center' }} xs={12}>
            <Button
              style={{ backgroundColor: '#09A963', zIndex: 1000 }}
              round
              justIcon
              onClick={() => toggleMap(index)}
            >
              <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
            </Button>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody className={classes.cardBodyContainer} style={isSmall ? { padding: '0 24px' } : {}}>
        {track.location
          ? <h6 className={classes.textInfo}>{track.location.name}</h6>
          : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0
            ? <Box component="div">
              <h6 className={classes.textInfo}>{`${t('from')}: ${track.locationFrom[0].name}`} {`${t('to')}: ${track.locationTo[0].name}`}</h6>
            </Box>
            : ''
        }
        <h4 className={classes.cardTitleSmall}>#{index + 1} {track.name}</h4>
        <h6>{track.timestamp ? `${t('date')}: ${moment(track.timestamp).format('DD/MM/YYYY')}` : ''}</h6>
        <Typography id={`${track.name}-${index}`} variant="body2" noWrap={true} style={{ marginTop: 0, marginBottom: 8, width: '100%', textAlign: 'center' }} className={classes.cardDescription}>
          {track.description}
        </Typography>
        {addShowMoreButton(`${track.name}-${index}`, track.description, track.name, track.media.length > 0 ? track.media[0].cropped : null)}

        <GridItem xs={12} sm={12} md={12} lg={12} style={{ padding: 0, marginTop: '20px' }}>
          <Container style={{ borderTop: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid  rgba(224, 224, 224, 1)', padding: '0' }}>
            <h6 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><EventAvailableIcon style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }} />{track.startedAt ? `Iniziata il: ${moment(track.startedAt).format('DD/MM/YYYY')}` : ''}</h6>
          </Container>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ padding: 0 }}>
          <Container style={{ borderTop: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', padding: '0' }}>
            <h6 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><EventBusyIcon style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }} />{track.completedAt ? `Completata il: ${moment(track.completedAt).format('DD/MM/YYYY')}` : ''}</h6>
          </Container>
        </GridItem>

        {track.inputs && track.inputs.length > 0 && track.inputs.some(x => (x.quantity && x.lotNumber))
          ? <Table className={classes.table} size="small" aria-label="Inputs/Outputs" style={{ marginTop: 18 }}>
            <TableHead>
              {track.outputs && track.outputs.length > 0
                ? <TableRow>
                  <TableCell align="left">{t('usedMaterials')}</TableCell>
                  <TableCell align="left">{t('producedMaterials')}</TableCell>
                </TableRow>
                : <TableRow>
                  <TableCell colSpan={2} align="left">{t('usedMaterials')}</TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody>
              {inputsOutputsTableDoby(track.inputs, track.outputs, track)}
            </TableBody>
          </Table>
          : null}

        <GridContainer direction="row" justify="flex-end" alignItems="flex-end" style={{ marginTop: 24 }}>
          <GridItem>
            <Button
              color="success"
              simple
              disabled={track.documents.length === 0}
              onClick={() => handleOpenTrackDoc(track)}
              style={{ display: track.documents.length === 0 ? 'none' : 'block' }}
            >
              {track.documents.length === 0 ? t('noAttachments') : t('showAttachments')}
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
    <Line></Line>
  </GridItem>
  </Fade>
}

export default TraceabilityMobileCard
