import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles, Paper, Box, Typography, Table, TableBody, TableRow, TableCell, Container } from '@material-ui/core'
import opentimestampsStyle from 'assets/jss/material-kit-pro-react/views/opentimestampsStyle'
import classNames from 'classnames'
import { getOpentimestampsInfo } from '../../actions/AppActions'
import { useTranslation } from 'react-i18next'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const useStyles = makeStyles(opentimestampsStyle)

export default function OpentimestampsPage () {
  const location = useLocation()
  // uuid del lotto passato come parametro nell'url
  const { lotNum, filename } = queryString.parse(location.search)

  const classes = useStyles()
  const { t } = useTranslation('opentimestamps')

  // variabili di stato del componente
  const [opentimestamps, setOpentimestamps] = useState({})
  const [lotNumber, setLotNumber] = useState('')

  // funzione che setta nello stato i dati per la renderizzazione della pagina
  const useFetchData = () => {
    useEffect(() => {
      const fetchData = async () => {
        const currInfos = await getOpentimestampsInfo(filename)
        setLotNumber(lotNum)
        setOpentimestamps(currInfos)
      }
      fetchData()
    }, [])
  }

  // funzione che genera la legenda degli eventi
  const generateEventsLegend = () => {
    const events = [
      {
        key: 'forks',
        label: 'Fork',
        color: '#159dfc'
      },
      {
        key: 'verify',
        label: 'Bitcoin Attestation',
        color: '#2db253'
      },
      {
        key: 'transactionId',
        label: 'Transactions',
        color: '#9c38e1'
      },
      {
        key: 'bitcoinBlockMerkleRoot',
        label: 'Comments',
        color: '#ee8f13'
      }
    ]

    return events.map((event, index) =>
      <Box display="flex" alignItems="center" flexDirection="row" key={`event-${index}-key`}>
        <Box mt={1} mb={1} mr={1} ml={2} component="div" style={{ backgroundColor: event.color, width: '20px', height: '20px' }}></Box>
        <Typography>{event.label}</Typography>
      </Box>
    )
  }

  // funzione ricorsiva che renderizza le righe degli eventi di opentimestamps
  const renderRows = (operation, index, pathNum = null) => {
    const { key } = operation
    switch (key) {
      case 'append': {
        return <TableRow key={`info-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes.neutral, classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            {pathNum !== null
              ? <Box display="flex" className={classes.wrapperBox}>
                <Box p={1} className={classes.pathBox}>{pathNum + 1}</Box>
                <Box p={1} className={classes.hashBox}>
                  <Typography component="span" variant="body2">
                    {operation.value[0]}
                    <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                  </Typography>
                </Box>
              </Box>
              : <Box p={1} style={{ display: 'inline-block' }} className={classes.valueBox}>
                <Typography component="span" variant="body2">
                  {operation.value[0]}
                  <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                </Typography>
              </Box>}
          </TableCell>
        </TableRow>
      }
      case 'sha256': {
        return <TableRow key={`iinfo-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes.neutral, classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            {pathNum !== null
              ? <Box display="flex" className={classes.wrapperBox}>
                <Box p={1} className={classes.pathBox}>{pathNum + 1}</Box>
                <Box p={1} className={classes.hashBox}>
                  <Typography component="span" variant="body2">
                    {operation.value[0]}
                    <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                  </Typography>
                </Box>
              </Box>
              : <Box p={1} style={{ display: 'inline-block' }} className={classes.valueBox}>
                <Typography component="span" variant="body2">
                  {operation.value[0]}
                  <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                </Typography>
              </Box>}
          </TableCell>
        </TableRow>
      }
      case 'prepend': {
        return <TableRow key={`info-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes.neutral, classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            {pathNum !== null
              ? <Box display="flex" className={classes.wrapperBox}>
                <Box p={1} className={classes.pathBox}>{pathNum + 1}</Box>
                <Box p={1} className={classes.hashBox}>
                  <Typography component="span" variant="body2">
                    <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[0]}</Typography>
                    {operation.value[1]}
                  </Typography>
                </Box>
              </Box>
              : <Box p={1} style={{ display: 'inline-block' }} className={classes.valueBox}>
                <Typography component="span" variant="body2">
                  <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[0]}</Typography>
                  {operation.value[1]}
                </Typography>
              </Box>}
          </TableCell>
        </TableRow>
      }
      case 'verify': {
        return <TableRow key={`info-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes[key], classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            <Box p={1} className={classes.attestationBox}>
              <Typography component="span" variant="body2">
                {operation.value[0]}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      }
      case 'transactionId': {
        return <TableRow key={`info-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes[key], classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            {pathNum !== null
              ? <Box display="flex" className={classes.wrapperBox}>
                <Box p={1} className={classes.pathBox}>{pathNum + 1}</Box>
                <Box p={1} className={classes.hashBox}>
                  <Typography component="span" variant="body2">
                    {operation.value[0]}
                    <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                  </Typography>
                </Box>
              </Box>
              : <Box p={1} style={{ display: 'inline-block' }} className={classes.valueBox}>
                <Typography component="span" variant="body2">
                  {operation.value[0]}
                  <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                </Typography>
              </Box>}
          </TableCell>
        </TableRow>
      }
      case 'bitcoinBlockMerkleRoot': {
        return <TableRow key={`info-row-${pathNum || ''}-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes[key], classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            {pathNum !== null
              ? <Box display="flex" className={classes.wrapperBox}>
                <Box p={1} className={classes.pathBox}>{pathNum + 1}</Box>
                <Box p={1} className={classes.hashBox}>
                  <Typography component="span" variant="body2">
                    {operation.value[0]}
                    <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                  </Typography>
                </Box>
              </Box>
              : <Box p={1} style={{ display: 'inline-block' }} className={classes.valueBox}>
                <Typography component="span" variant="body2">
                  {operation.value[0]}
                  <Typography className={classes.highlightText} component="span" variant="body2">{operation.value[1]}</Typography>
                </Typography>
              </Box>}
          </TableCell>
        </TableRow>
      }
      case 'forks': {
        const forkNode = <TableRow key={`fork-row-${index}`}>
          <TableCell classes={{ body: classes.noPadding }} className={classNames(classes[key], classes.label)}>
            <Typography component="span" variant="body2">
              {operation.label}
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.noPadding }}>
            <Box p={1}>
              <Typography component="span" variant="body2">
                {`Forked in ${operation.value.length} paths`}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>

        return [forkNode].concat(operation.value.map((fork, pathIndex) => fork.map((forkOp, forkIndex) => renderRows(forkOp, forkIndex, pathIndex)))).flat()
      }
      default: {
        return null
      }
    }
  }

  // funzione che genera le righe degli eventi di opentimestamps
  const createRows = () => {
    const elements = opentimestamps && opentimestamps.info
    return elements.slice(1).map((operation, index) => renderRows(operation, index))
  }

  // fetch dei dati utili alla renderizzazione della pagina
  useFetchData()

  return <SkeletonTheme color="#ddebdd" highlightColor="#d3e3d3">
    {lotNumber && opentimestamps && opentimestamps.info && opentimestamps.info.length > 0 ? <Paper elevation={4}>
      <Box pt={4} pb={4} className={classes.root} width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={4} p={1}>
          <Typography className={classes.textLight} component="p" variant="h5">{t('title', { lotNumber })}</Typography>
        </Box>
        <Typography className={classes.textLight} component="p" variant="body2">{opentimestamps.info[0].label}</Typography>
        <Box mt={1} p={1} className={classes.digestBox}>
          <Typography className={classes.textNormal} component="p" variant="body1">{opentimestamps.info[0].value}</Typography>
        </Box>
      </Box>
      <Box pt={6} width="100%" display="flex" justifyContent="center">
        <Box className={classes.legendWrapper} display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
          {generateEventsLegend()}
        </Box>
      </Box>
      <Box pt={4} pb={4} className={classes.content} display="flex" alignItems="flex-start" justifyContent="center">
        <Table className={classes.table}>
          <TableBody className={classes.tableBody}>
            {createRows()}
          </TableBody>
        </Table>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" height="100px" className={classes.root} width="100%">
        <Container maxWidth="md">
          <Box width="100%" display="flex" alignItems="center">
            <Typography component="div" variant="caption">Powered by</Typography>
            <Box ml={1}>
              <a href="https://opentimestamps.org/" target="_blank" rel="noopener noreferrer">
                <img alt="opentimestamps logo" src={require('assets/img/opentimestamps.png')} />
              </a>
            </Box>
          </Box>
        </Container>
      </Box>
    </Paper> : <Paper>
      <Box pt={4} pb={4} className={classes.root} width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={4} p={1}>
          <Typography className={classes.textLight} component="p" variant="h5">{t('title', { lotNumber })}</Typography>
        </Box>
      </Box>
      <Box pt={6} width="100%" display="flex" justifyContent="center">
        <Box className={classes.legendWrapper} display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
          {generateEventsLegend()}
        </Box>
      </Box>
      <Box pt={4} pb={4} className={classes.content} display="flex" alignItems="flex-start" justifyContent="center">
        <Table className={classes.table}>
          <Skeleton height={100} count={10} />
        </Table>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" height="100px" className={classes.root} width="100%">
        <Container maxWidth="md">
          <Box width="100%" display="flex" alignItems="center">
            <Typography component="div" variant="caption">Powered by</Typography>
            <Box ml={1}>
              <a href="https://opentimestamps.org/" target="_blank" rel="noopener noreferrer">
                <img alt="opentimestamps logo" src={require('assets/img/opentimestamps.png')} />
              </a>
            </Box>
          </Box>
        </Container>
      </Box>
    </Paper>}
  </SkeletonTheme>
}
