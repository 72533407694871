import React, { useContext, useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Skeleton from 'react-loading-skeleton'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Product from 'components/Product/Product'
import { AppContext } from '../../context/AppState'
import { CardMedia, CardContent, Typography, Link, Button, Card, CardActions } from '@material-ui/core'
import './style.css'
import { useTranslation } from 'react-i18next'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import Fade from 'react-reveal/Fade'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
// import { SRLWrapper } from 'simple-react-lightbox'

const useStyles = makeStyles(presentationStyle)

const AttributesGrid = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const { company } = state
  const { t } = useTranslation('attributes')

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [cta, setCta] = useState([])

  useEffect(() => {
    const images = product.additionalAttributes
      .filter(element => element.type === 'image')
      .map(elem => ({
        src: elem.link,
        width: 4,
        height: 3,
        key: elem.link + Math.random()
      }))
    console.log('images:::::::', images)

    setImages(images)

    const videos = product.additionalAttributes
      .filter(element => element.type === 'video')
    console.log('images:::::::', videos)

    setVideos(videos)

    const cta = product.additionalAttributes
      .filter(element => element.type === 'button')
    console.log('cta:::::::', cta)

    setCta(cta)

    // function getId (url) {
    //   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    //   const match = url.match(regExp)

    //   return (match && match[2].length === 11)
    //     ? match[2]
    //     : null
    // }

    // const videoId = getId('http://www.youtube.com/watch?v=zbYf5_S7oJo')
    // const iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/' +
    //   videoId + '" frameborder="0" allowfullscreen></iframe>'

    // console.log('Video ID:', iframeMarkup + videoId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openLightbox = useCallback((event, { images, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const isSmall = useMediaQuery('(max-width: 600px)')

  return <GridContainer style={isSmall ? { paddingLeft: '0px', paddingRight: '0px', marginTop: '0px' } : null}>
    <GridItem xs={12} md={12}>
      <Product showDescripton={true}/>
    </GridItem>
    <GridItem xs={12} md={3}>
      <h3 className="sticky">{t('title')}</h3>
    </GridItem>
    <GridItem xs={12} md={9}>
      {
        product && product.attributes
          ? product.attributes.map((attribute, index) => <GridItem key={`${attribute.name}${attribute.value}`} xs={12} md={12} lg={12} xl={12} className={classes.contentGrid} style={isSmall && index !== (product.attributes.length - 1) ? { paddingBottom: '0px' } : {}}>
            <Fade big>
              <div className="attributeContainer">
                <b>{attribute.name || <Skeleton duration={0.7} />}</b>
                <span>{attribute.value || <Skeleton duration={0.7} count={3} />}</span>
              </div>
            </Fade>
          </GridItem>)
          : [<GridItem key={'skeleton-1'} xs={12} md={6} xl={4} className={classes.contentGrid} style={isSmall ? { paddingBottom: '0px' } : {}}>
            <h4><Skeleton duration={0.7} /></h4>
            <Skeleton duration={0.7} count={2} />
          </GridItem>,
          <GridItem key={'skeleton-2'} xs={12} md={6} xl={4} className={classes.contentGrid} style={isSmall ? { paddingBottom: '0px' } : {}}>
            <h4><Skeleton duration={0.7} /></h4>
            <Skeleton duration={0.7} count={2} />
          </GridItem>]
      }
      {product && product.additionalImages
        ? (<>

          <GridItem xs={12} md={12} xl={12}></GridItem>
          {product.additionalImages.map((image, index) =>
            <GridItem key={`${image.original}`} xs={12} md={6} xl={4} className={`${classes.contentGrid} additionalImg`}>
              <Fade big>
                <CardMedia component="img" className={isSmall ? classes.cardMediaMobile : classes.cardMediaAdditionalImage} image={(image && image.cropped ? image.cropped : image.original)} />
              </Fade>
            </GridItem>
          )}

        </>
        )
        : null
      }
      <GridContainer>
        {cta && cta.length > 0
          ? <>  <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
          Call to action
          </Typography>
          <GridContainer spacing={5}>
            {cta.map(elem =>
              <GridItem key={`${cta.link}`} xs={12} md={6} xl={6} className={classes.contentGrid}>
                <Card style={{ padding: '15px' }}>
                  <CardContent>
                    <Typography variant="h5" color="textSecondary" component="h5">
                      {elem.description}
                    </Typography>
                    <Typography variant="body2" component="p">
                      Da questo link potrai approfondire l&apos;argomento
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      style={{
                        backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289',
                        color: 'white'
                      }}
                      className="btnLink"
                      variant="contained"
                      endIcon={<ArrowForwardIosOutlinedIcon />}
                    >
                      <Link color="inherit" target='_blank' href={elem.link}>Scopri di più</Link>
                    </Button>

                  </CardActions>
                </Card>
              </GridItem>
            )}
          </GridContainer>
          </>
          : ''
        }

        {videos && videos.length > 0
          ? <>  <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
          Video
          </Typography>
          </>
          : null
        }
        {product && product.additionalAttributes && product.additionalAttributes.length > 0
          ? (<>
            {product.additionalAttributes.map(attr => {
              if (attr.type === 'video') {
                if (new URL(attr.link).hostname.includes('youtube') || new URL(attr.link).hostname.includes('vimeo')) {
                  return (
                    <GridItem key={`${attr.link}`} xs={12} md={12} xl={12} className={classes.contentGrid}>

                      <div className={classes.videoWrapper}>
                        <iframe
                          title = {attr.link}
                          className={classes.videoWrapperFrame}
                          width="560" height="315"
                          src={attr.link}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      </div>

                      <CardContent style={{ padding: '10px', background: '#fafafa' }}>
                        <Typography style={{ padding: ' 10px' }} variant="h6" color="textSecondary" component="p">
                          {attr.description}
                        </Typography>
                      </CardContent>

                    </GridItem>
                  )
                } else {
                  return (
                    <GridItem key={`${attr.link}`} xs={12} md={12} xl={12} className={classes.contentGrid}>
                      <div className={classes.videoWrapper}>
                        <video controls className={classes.videoWrapperFrame} src={(attr.link)} />
                      </div>
                      <CardContent style={{ padding: '10px 0', background: '#fafafa' }}>
                        <Typography style={{ padding: '10px' }} variant="h6" color="textSecondary" component="p">
                          {attr.description}
                        </Typography>
                      </CardContent>
                    </GridItem>
                  )
                }
              } else {
                return null
              }
            })
            }
          </>
          )
          : null
        }

        {images && images.length > 0
          ? <>
            <GridContainer className="imageContainer">
              <GridItem xs={12} md={12} lg={12}>
                <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
            Immagini
                </Typography>
              </GridItem>
              <GridItem xs={12} md={12} lg={12}>
                <Gallery photos={images} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={images.map(x => ({
                          ...x,
                          srcset: x.link,
                          caption: x.description
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </GridItem>
            </GridContainer>
          </>
          : null
        }
      </GridContainer>
    </GridItem>

  </GridContainer>
}
export default AttributesGrid
