import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'

import bg from 'assets/img/notFound.png'

const useStyles = makeStyles(presentationStyle)

const NotFound = () => {
  const classes = useStyles()
  const { t } = useTranslation('notFound')

  return <div className={classes.bodySection}>
    <Container>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <div className={classes.notFoundContainer} style={{ backgroundImage: `url(${bg})` }}></div>
      </Box>
    </Container>
    <Box width="100%" mt={5} pr='40px' pl='40px' style={{ textAlign: 'center' }}>
      <Typography className={classes.h1Text} variant="h5" component="h1">{t('message')}</Typography>
    </Box>
  </div>
}

export default NotFound
