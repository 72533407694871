import React, { useContext } from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import CloseIcon from '@material-ui/icons/Close'
const DescriptionDialog = (props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState('sm')
  const { t } = useTranslation('descriptionDialog')

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { openTrackDesc, selectedTrackDesc } = state

  // funzione che chiude modal documenti
  const handleCloseTrackDesc = () => {
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: false
    })
  }

  return <Dialog
    fullScreen={fullScreen}
    scroll={'paper'}
    open={openTrackDesc}
    onClose={handleCloseTrackDesc}
    aria-labelledby="track-description-dialog"
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    <DialogTitle id="track-description-dialog">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {`${t('title')}: ${selectedTrackDesc.name}`}
        <CloseIcon onClick={handleCloseTrackDesc}/>
      </div>
    </DialogTitle>
    <div>
      <DialogContent dividers={true} style={{ padding: 0, paddingBottom: '50px' }}>
        {selectedTrackDesc.imgUrl ? <img alt={selectedTrackDesc.name} src={selectedTrackDesc.imgUrl} style={{ width: '100%', height: 'auto' }} /> : null}
        <Typography component="p" style={{ width: '100%', whiteSpace: 'pre-wrap', padding: 16 }}>{selectedTrackDesc.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTrackDesc} color="primary">
          {t('closeButton')}
        </Button>
      </DialogActions>
    </div>
  </Dialog>
}

export default DescriptionDialog
