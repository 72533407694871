import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Dialog, DialogTitle, DialogContent, Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, useTheme } from '@material-ui/core'
import DownLoadIcon from '@material-ui/icons/CloudDownload'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import Link from '@material-ui/core/Link'
import Button from 'components/CustomButtons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import CloseIcon from '@material-ui/icons/Close'
// import configuration from '../../configuration'
const useStyles = makeStyles(presentationStyle)

const DocumentsDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation('documentsDialog')

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { openTrackDoc, selectedTrackDoc } = state

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmall = useMediaQuery('(max-width: 600px)')
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState('sm')

  // funzione che chiude modal documenti
  const handleCloseTrackDoc = () => {
    dispatch({
      type: 'SET_OPEN_TRACK_DOC',
      payload: false
    })
  }

  // Funzione che scarica il documento
  // const downloadDocument = (name, url) => {
  //   const a = document.createElement('a')
  //   document.body.appendChild(a)
  //   a.style = 'display: none'
  //   a.href = url
  //   a.target = '_blank'
  //   a.download = name
  //   a.click()
  //   window.URL.revokeObjectURL(url)
  // }

  return <Dialog
    fullScreen={fullScreen}
    scroll={'paper'}
    open={openTrackDoc}
    onClose={handleCloseTrackDoc}
    aria-labelledby="track-document-dialog"
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    <DialogTitle id="track-document-dialog">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {`${t('title')}: ${selectedTrackDoc.name}`}
        <CloseIcon onClick={handleCloseTrackDoc}/>
      </div>
    </DialogTitle>
    <div>
      <DialogContent dividers={true} style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Box p={2}>
          <Typography style={{ marginTop: '8px', color: '#9e9e9e' }} component="h2" variant="body2">{selectedTrackDoc.documents && selectedTrackDoc.documents.length > 0 ? t('downloadMessage') : t('noDocsMessage')}</Typography>
        </Box>
        <List>
          {selectedTrackDoc.documents && selectedTrackDoc.documents.map((document, docIndex) => <ListItem style={{ margin: '16px 0', backgroundColor: docIndex % 2 === 0 ? 'white' : '#fafafa', padding: '16px' }} key={`doc-${docIndex}`}>
            <ListItemText
              primary={<div className={isSmall ? classes.textEllipsisMobile : classes.textEllipsis}>
                {document.name || t('defaultName')}
              </div>}
            />
            <ListItemSecondaryAction>
              <Button variant="contained" color="primary">
                <a style={{ color: 'white' }} href={ document.fileUrl} target='_blank' rel="noopener noreferrer" endIcon={<DownLoadIcon/>}>
                  {t('downloadButton')}
                </a>
              </Button>
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>
      </DialogContent>
    </div>
  </Dialog>
}

export default DocumentsDialog
