import React, { useEffect, useContext } from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppContext } from '../../context/AppState'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Parallax from 'components/Parallax/Parallax.js'
import MenuMobile from 'components/Menu/MenuMobile'
import NotFound from 'components/NotFound/NotFound'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Box, Container, Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import AttributesGrid from 'components/AttributesGrid/AttributesGrid.js'
import CompanyInfo from 'components/CompanyInfo/CompanyInfo.js'
import CertificationsTable from 'components/CertificationsTable/CertificationsTable.js'
import Traceability from 'components/Traceability/Traceability.js'
import CertDialog from 'components/CertDialog/CertDialog.js'
import DescriptionDialog from 'components/DescriptionDialog/DescriptionDialog.js'
import DocumentsDialog from 'components/DocumentsDialog/DocumentsDialog.js'
import TopBar from 'components/Menu/TopBar'
import Footer from 'components/Footer/Footer'
import { companyToAnalytics } from 'companyToAnalytics'
import { getProduct, getLots, getCompany, populateLotData } from '../../actions/AppActions'

const useStyles = makeStyles(presentationStyle)

// main hook
export default function PresentationPage () {
  // React-router hook per prendere i parametri passati nell'url (uuid o gtin prodotto, uuid o lotNumber del lotto)
  const { prod, lot } = useParams()
  // stato globale dell'app e funzione che fa il dispatch delle azioni
  const { state, dispatch } = useContext(AppContext)

  const { sectionActive, product, informationFound, company, companyLoaded } = state

  // const [formattedUrl, setFormattedUrl] = useState(company.website)

  // funzione che controlla il link al social e se manca l'http lo aggiunge
  const checkHttpInSocialLinks = (socialLinks) => {
    Object.keys(socialLinks).forEach(social => {
      if (socialLinks[social] && (!socialLinks[social].startsWith('http://') && !socialLinks[social].startsWith('https://'))) {
        socialLinks[social] = `http://${socialLinks[social]}`
      }
    })
    return socialLinks
  }

  // funzione che scarica i dati del prodotto scansionato e della sua azienda produttrice
  async function fetchProducts () {
    try {
      const product = await getProduct(prod)
      const company = await getCompany(product.companyId)

      if (product && product.social) {
        const { website, ...social } = checkHttpInSocialLinks({ ...product.social, website: product.website })
        product.social = social
        product.website = website
      }

      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
      dispatch({
        type: 'SET_COMPANY',
        payload: company
      })
      dispatch({
        type: 'SET_COMPANY_LOADED',
        payload: true
      })
    } catch (e) {
      dispatch({
        type: 'SET_INFO_FOUND',
        payload: false
      })
    }
  }

  // funzione che cambia lingua in base all'elemento selezionato
  // const selectLanguage = (label) => {
  //   switch (label) {
  //     case 'ITA': {
  //       return changeLanguage('it-IT')
  //     }
  //     case 'ENG': {
  //       return changeLanguage('en-US')
  //     }
  //     default: {
  //       return changeLanguage('it-IT')
  //     }
  //   }
  // }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLots () {
    try {
      let lots = await getLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        const lotNum = lots.lotNumber
        dispatch({
          type: 'SET_EXPIRATION_DATE',
          payload: expirationDate
        })
        dispatch({
          type: 'SET_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lotNum
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // Custom hook che fa il fetch dei dati in base ai parametri ricevuti nell'url
  const useFetchData = () => {
    useEffect(() => {
      if (!lot) {
        fetchProducts()
      } else {
        fetchProducts()
        fetchLots()
      }
    }, [])
  }

  // chiamata all'hook che fa il fetch dei dati
  useFetchData()

  // definisco stili e media query
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')

  // useEffect(() => {
  //   // const url = company.website
  //   console.log('company.website:::::::::', formattedUrl)
  //   // const formattedUrl = company.website.substring(1, 2, 3)

  //   // setFormattedUrl({ formattedUrl: formattedUrl })
  //   // console.log('formattedUrl', formattedUrl)
  // }, [])

  const ga = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${companyToAnalytics[company.uuid] ? companyToAnalytics[company.uuid] : companyToAnalytics.general}');`

  useEffect(() => {
    if (companyLoaded) {
      var scriptTag = document.createElement('script')
      // scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-LT5T6BXHS4'
      // scriptTag.async = true
      scriptTag.async = true
      // if (product && product.gtin === 't0000000000010-FUB5') {
      //   scriptTag.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${analyticsCode.fusilli}`)
      // }
      scriptTag.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${companyToAnalytics[company.uuid] ? companyToAnalytics[company.uuid] : companyToAnalytics.general}`)
      var scriptTag1 = document.createElement('script')
      scriptTag1.appendChild(document.createTextNode(ga))
      document.head.appendChild(scriptTag)
      document.head.appendChild(scriptTag1)
    }

    if (company.uuid === '66a78ee9-366e-4b9f-8cef-0ee6984c3c97') {
      const yandex = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      
      ym(86241085, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
      });
      `
      var scriptTagYandex = document.createElement('script')
      scriptTagYandex.appendChild(document.createTextNode(yandex))
      document.head.appendChild(scriptTagYandex)
    }

    // ReactGA.pageview(window.location.pathname + window.location.search)
  }, [companyLoaded, company.uuid, ga])

  return !informationFound
    ? <NotFound />
    : (
      <SkeletonTheme color="#ddebdd" highlightColor="#d3e3d3">
        {/* <div style={{ position: isSmall ? 'fixed' : 'absolute', top: 0, right: isSmall ? '20px' : '60px', zIndex: '999' }}>
          <Dropdown
            buttonText={returnAbbr(currLang)}
            buttonProps={{
              color: 'primary',
              startIcon: <LanguageIcon/>
            }}
            dropdownList={[
              'ITA',
              'ENG'
            ]}
            onClick={(e) => selectLanguage(e)}
          />
        </div> */}
        <div>
          {/* dialog track documents */}
          <DocumentsDialog />
          {/* dialog track description */}
          <DescriptionDialog />
          {/* dialog certifications mobile */}
          <CertDialog />
          {/* content */}
          <TopBar/>
          <div style={{ marginTop: 50, backgroundColor: isSmall ? '#fafafa' : '' }}>
            {
              product && product.backgroundImage && product.backgroundImage.cropped
                // ? <Parallax style={{ height: isSmall ? '20vh' : '30vh', display: isSmall && sectionActive !== 1 ? 'none' : '' }}
                ? <Parallax style={{ height: isSmall ? '20vh' : '30vh' }} image={product.backgroundImage.cropped} className={classes.parallax}></Parallax>
                : <Box id="skeleton-background"><Skeleton duration={0.7} height={isSmall ? '20vh' : '30vh'} width="100%" /></Box>
            }
            <div>

              <Container>
                {company.metadata && company.metadata.showSiteButton === 'yes' && company.metadata && company.metadata.buttonPosition === 'left'
                  ? <>
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justify="flex-start"
                    >

                      <a
                        className="buttonSite"
                        href={company.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}
                      >
                        {company.metadata && company.metadata.customTextButton !== ''
                          ? company.metadata.customTextButton
                          : 'Vai al sito'
                        }
                        <ArrowForwardIosIcon/>
                      </a>
                    </Grid>
                  </>
                  : company.metadata && company.metadata.showSiteButton === 'yes' && company.metadata && company.metadata.buttonPosition === 'center'
                    ? <>
                      <Grid
                        container
                        display="flex"
                        alignItems="center"
                        justify="center"
                      >

                        <a
                          className="buttonSite"
                          href={company.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}
                        >
                          {company.metadata && company.metadata.customTextButton !== ''
                            ? company.metadata.customTextButton
                            : 'Vai al sito'
                          }
                          <ArrowForwardIosIcon/>
                        </a>
                      </Grid>
                    </>
                    : company.metadata && company.metadata.showSiteButton === 'yes' && company.metadata && company.metadata.buttonPosition === 'right'
                      ? <>
                        <Grid
                          container
                          display="flex"
                          alignItems="center"
                          justify="flex-end"
                        >

                          <a
                            className="buttonSite"
                            href={company.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}
                          >
                            {company.metadata && company.metadata.customTextButton !== ''
                              ? company.metadata.customTextButton
                              : 'Vai al sito'
                            }
                            <ArrowForwardIosIcon/>
                          </a>
                        </Grid>
                      </>
                      : null
                }
              </Container>
              <Container className="tabHome">
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="tabContent">
                    {sectionActive === 5 ? (
                      <AttributesGrid />
                    ) : sectionActive === 2 ? (
                      <CompanyInfo />
                    ) : sectionActive === 3 ? (
                      <CertificationsTable />
                    // ) : sectionActive === 4 ? (
                    //   <AllergenesTable />
                    ) : sectionActive === 1 ? (
                      <Traceability />
                    ) : ''}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
        <Footer/>
        {isSmall
          ? <MenuMobile/> : ''
        }
      </SkeletonTheme>
    )
}
