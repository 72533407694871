import Axios from 'axios'
import configuration from '../configuration.js'

const TRUSTY_API = configuration.apiBaseUrl

// get che ritorna un prodotto in base al suo uuid o gtin
export const getProduct = async (uuid) => {
  try {
    const product = await Axios.get(`${TRUSTY_API}/public/products/${uuid}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })
    console.log('new product => ', product.data.data)
    return product.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del prodotto')
  }
}

// get che ritorna un'azienda in base al companyId
export const getCompany = async (uuid) => {
  try {
    const company = await Axios.get(`${TRUSTY_API}/public/companies/${uuid}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return company.data.data
  } catch (err) {
    throw new Error("Impossibile scaricare i dati dell' azienda")
  }
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLots = async (productUuid, lotUuid) => {
  try {
    const lots = await Axios.get(`${TRUSTY_API}/public/products/${productUuid}/lots/${lotUuid}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })
    console.log('lots.data: ', lots.data)
    return lots.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}

// Funzione che prende un prodotto tramite il suo uuid
const getProdById = async (id) => {
  const response = await Axios.get(`${TRUSTY_API}/public/products/${id}`, {
    params: {
      // filter: JSON.stringify({ status: { $ne: 'deleted' } })
      filter: JSON.stringify({ $or: [{ status: 'deleted' }] })
    },
    headers: {
      authorization: `Basic ${configuration.token}`
    }
  })

  return response && response.data && response.data.data ? response.data.data : []
}

// Funzione che prende le informazioni mancanti riguardanti facilities e prodotti e le inseriscono nel lotto
export const populateLotData = async (lots) => {
  if (Array.isArray(lots.traceability)) {
    for (const index in lots.traceability) {
      const track = lots.traceability[index]
      // prprietà aggiunta per la visualizzazione delle card nella scheda tracciabilità
      track.activeSection = { card: index, showMap: false }

      if (Array.isArray(track.inputs)) {
        for (const input of track.inputs) {
          input.inputData = await getProdById(input.productId)
        }
      }

      if (Array.isArray(track.outputs)) {
        for (const output of track.outputs) {
          output.outputData = await getProdById(output.productId)
        }
      }
    }
  }
  return lots
}

// funzione che prende le informazioni relative alle operazioni di opentimestamps, partendo dal parametro 'filename' che corrisponde all'url del file ots del lotto esaminato
export const getOpentimestampsInfo = async (filename) => {
  try {
    const opentimestampsResponse = await Axios.get(`${configuration.apiBaseUrl}/public/opentimestamps/info?filename=${filename}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return opentimestampsResponse.data.data
  } catch (err) {
    console.log(err)
  }
}
