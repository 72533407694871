import React, { useContext, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Typography, Link } from '@material-ui/core'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
// import Card from 'components/Card/Card'
// import CardHeader from 'components/Card/CardHeader'
// import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Map from 'components/Map/Map'
import Button from 'components/CustomButtons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import Container from '@material-ui/core/Container'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import Fade from 'react-reveal/Fade'
import Line from '../Line/Line.js'

const useStyles = makeStyles(presentationStyle)

// funzione che ritorna il corpo della tabella degli input/output
const inputsOutputsTableDoby = (inputs, outputs, track) => {
  const array = [...Array(!outputs ? inputs.length : inputs.length >= outputs.length ? inputs.length : outputs.length)]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs[i] && inputs[i].lotNumber) {
      newLine[0] = `${inputs[i].inputData.name} (Lot ${track ? track.inputs[i].lotNumber : ''}) ${track ? (track.inputs[i].quantity != null ? ` - ${track.inputs[i].quantity} ${inputs[i].uom}` : '') : ''}`
    }
    if (outputs && outputs[i] && outputs[i].quantity) {
      newLine[1] = `${outputs[i].outputData.name} (Lot ${track.outputs ? track.outputs[i].lotNumber : ''}) ${track ? (track.outputs[i].quantity != null ? ` - ${track.outputs[i].quantity} ${outputs[i].uom}` : '') : ''}`
    }
    if (newLine && newLine.length > 0) {
      finalArray.push(newLine)
    }
  })

  return finalArray.map((elem, i) => {
    return (
      <TableRow key={i}>
        <TableCell align="left">{elem[0]}</TableCell>
        {outputs && <TableCell align="left">{elem[1]}</TableCell>}
      </TableRow>
    )
  })
}

const TraceabilityCard = (props) => {
  const classes = useStyles()
  // const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCard')
  const { track, index } = props

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { currLot, company } = state

  // funzione che apre modal documenti
  const handleOpenTrackDoc = (track) => {
    dispatch({
      type: 'SET_TRACK_DOC',
      payload: track
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DOC',
      payload: true
    })
  }

  // funzione che mostra/nasconde la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex, showOnly = false) => {
    const lot = Object.assign({}, currLot)
    const traceability = lot.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, { activeSection: { card: cardIndex, showMap: showOnly ? true : !activeSection.showMap }, ...track })
    lot.traceability = traceability
    dispatch({
      type: 'SET_LOT',
      payload: lot
    })
  }

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }

  // Uso una funzione di callback perchè riusciamo a capire
  // le dimensioni del nodo anche se viene renderizzato successivamente
  const lengthRef = useCallback(node => {
    if (node !== null) {
      dispatch({
        type: 'SET_LOT',
        payload: currLot
      })
    }
  }, [currLot, dispatch])

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      if (elem.scrollWidth > elem.clientWidth) {
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ width: '100%' }}>
            <Link onClick={() => handleOpenTrackDesc(description, name, imgUrl)} color="primary" style={{ cursor: 'pointer' }}>
              {t('showMore')}
            </Link>
          </Box>
        )
      } else {
        return ''
      }
    }
  }

  useEffect(() => {
    if (track.media.length === 0) {
      toggleMap(index, true)
    }

  // eslint-disable-next-line
  }, [])

  return (<Fade big>
    <div className="traciabilityCard" >
      <GridContainer style={{ background: '#fff', padding: '15px 0', marginBottom: '0px', boxShadow: '0 0 5px 0 #E0E0E0' }}>
        {/* {track.media && track.media.cropped && track.media.original */}
        {track.media.length > 0 && track.media[0].cropped !== 'placeholder' && track.media[0].original !== 'placeholder'
          ? <GridItem xs={12} sm={12} md={4} lg={4}>
            {
              track.location && track.location.lat && track.location.lng && track.activeSection.showMap
                ? <div className={classes.imgContainer} onMouseLeave={() => track.media.length > 0 ? toggleMap(index) : null}>
                  <Map isHeader={true} lat={track.location.lat} lng={track.location.lng} />
                </div>
                : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationFrom[0].lat && track.locationFrom[0].lng &&
                track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0 && track.locationTo[0].lat && track.locationTo[0].lng && track.activeSection.showMap
                  ? <div className={classes.imgContainer} onMouseLeave={() => track.media.length > 0 ? toggleMap(index) : null}>
                    <Map isHeader={true} zoom={14} positions={[[track.locationFrom[0].lat, track.locationFrom[0].lng], [track.locationTo[0].lat, track.locationTo[0].lng]]} lat={track.locationTo[0].lat} lng={track.locationTo[0].lng} />
                  </div>
                  : <div onMouseEnter={() => toggleMap(index)} className={classes.imgContainer} style={{ backgroundImage: `url('${track.media[0].cropped}')` }}>
                  </div>
            }
          </GridItem>
          : track.location && track.location.lat && track.location.lng
            ? <GridItem xs={12} sm={12} md={4} lg={4}>
              <Map isHeader={true} lat={track.location.lat} lng={track.location.lng} />
            </GridItem>
            : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationFrom[0].lat && track.locationFrom[0].lng &&
            track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0 && track.locationTo[0].lat && track.locationTo[0].lng
              ? <GridItem xs={12} sm={12} md={4} lg={4}>
                <Map isHeader={true} zoom={14} positions={[[track.locationFrom[0].lat, track.locationFrom[0].lng], [track.locationTo[0].lat, track.locationTo[0].lng]]} lat={track.locationTo[0].lat} lng={track.locationTo[0].lng} />
              </GridItem>
              : 'test'
        }

        <GridItem xs={12} sm={12} md={4} lg={8}>
          <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: 0, height: '100%' }}>
            <Container style={{ padding: 0 }}>
              <h4 className={classes.cardTitleSmall}>#{index + 1} {track.name}</h4>
              <Typography ref={lengthRef} id={`${track.name}-${index}`} variant="body2" noWrap={true} style={{ marginTop: 0, marginBottom: 8, width: '100%' }} className={classes.cardDescription}>
                {track.description}
              </Typography>
              {addShowMoreButton(`${track.name}-${index}`, track.description, track.name, track.media.length > 0 ? track.media[0].cropped : null)}
            </Container>
            <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 0 }}>
              <Button
                onMouseEnter={() => track.media.length > 0 ? toggleMap(index) : null}
                style={{ backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289', zIndex: 1000 }}
                round
                justIcon
                onClick={() => track.media.length > 0 ? toggleMap(index) : null}
              >
                <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : '#FFF' }} />
              </Button>
              {track.location
                ? <h6 className={classes.textInfo} style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}>{track.location.name}</h6>
                : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0
                  ? <Box component="div">
                    <h6 className={classes.textInfo} style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}>{`${t('from')}: ${track.locationFrom[0].name}`} {`${t('to')}: ${track.locationTo[0].name}`}</h6>
                  </Box>
                  : ''
              }
            </Container>
          </Container>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '15px', paddingBottom: '15px', marginTop: '20px' }}>
          <Container style={{ borderTop: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid  rgba(224, 224, 224, 1)', padding: '0' }}>
            <h6 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><EventAvailableIcon style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }} />{track.startedAt ? `${t('startDate')}: ${moment(track.startedAt).format('DD/MM/YYYY')}` : ''}</h6>
          </Container>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{ padding: '15px', marginTop: '20px' }}>
          <Container style={{ borderTop: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', padding: '0' }}>
            <h6 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><EventBusyIcon style={{ color: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }} />{track.completedAt ? `${t('completedDate')}: ${moment(track.completedAt).format('DD/MM/YYYY')}` : ''}</h6>
          </Container>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {track.inputs && track.inputs.length > 0 && track.inputs.some(x => (x.quantity && x.lotNumber))
            ? <Table className={`${classes.table} phasestable`} size="small" aria-label="Inputs/Outputs" style={{ marginTop: 18 }}>
              <TableHead>

                {track.outputs && track.outputs.length > 0
                  ? <TableRow>
                    <TableCell align="left">{t('usedMaterials')}</TableCell>
                    <TableCell align="left">{t('producedMaterials')}</TableCell>
                  </TableRow>
                  : <TableRow>
                    <TableCell colSpan={2} align="left">{t('usedMaterials')}</TableCell>
                  </TableRow>
                }

              </TableHead>
              <TableBody>
                {inputsOutputsTableDoby(track.inputs, track.outputs, track)}
              </TableBody>
            </Table>
            : null}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Button
            color="success"
            simple
            disabled={track.documents.length === 0}
            onClick={() => handleOpenTrackDoc(track)}
            style={{ display: track.documents.length === 0 ? 'none' : 'block', marginTop: 10, float: 'right' }}
          >
            {t('showAttachments') + '(' + track.documents.length + ')' }
          </Button>
        </GridItem>
      </GridContainer>
      <Line></Line>
    </div>
  </Fade>
  )
}

export default TraceabilityCard
