import React, { useContext } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AppContext } from '../../context/AppState'

// colonne per tabella certificazione
const useCertColumns = () => {
  const { t } = useTranslation('certificationsTable')

  return [
    { title: t('name'), field: 'name' },
    { title: t('description'), field: 'description' },
    { title: t('authority'), field: 'authority' },
    { title: t('protocolNum'), field: 'num' }
  ]
}

const useCertMobileColumns = () => {
  const { t } = useTranslation('certificationsTable')

  return [
    { title: t('name'), field: 'name' },
    { title: t('description'), field: 'description' }
  ]
}

// traduzuone per tabella
const useTableCurrLang = () => {
  const { t } = useTranslation('certificationsTable')
  return {
    toolbar: {
      searchPlaceholder: t('table.toolbar'),
      searchTooltip: t('table.toolbar')
    },
    body: {
      emptyDataSourceMessage: t('table.emptyData')
    },
    pagination: {
      labelRowsSelect: t('table.rows'),
      labelDisplayedRows: t('table.labelRows'),
      firstTooltip: t('table.first'),
      previousTooltip: t('table.previous'),
      nextTooltip: t('table.next'),
      lastTooltip: t('table.last')
    }
  }
}

const CertificationsTable = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')
  const tableCurrLang = useTableCurrLang()
  const certColumns = useCertColumns()
  const certMobileColumns = useCertMobileColumns()
  const { t } = useTranslation('certificationsTable')
  const { state, dispatch } = useContext(AppContext)

  const { product } = state

  // funzione che apre modal documenti
  const handleOpenCert = (cert) => {
    dispatch({
      type: 'SET_CERT',
      payload: cert
    })
    dispatch({
      type: 'SET_OPEN_CERT',
      payload: true
    })
  }

  return <div style={isSmall ? { marginTop: '16px' } : {}}>
    <GridContainer style={{ width: '100%' }}>
      <GridItem xs={12} md={3}>
        <h3 className="sticky">{t('title')}</h3>
      </GridItem>
      <GridItem xs={12} md={9} style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}>
        <MaterialTable
          title={t('title')}
          localization={tableCurrLang}
          columns={isSmall ? certMobileColumns : certColumns}
          data={product && product.certifications}
          options={{
            emptyRowsWhenPaging: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            showEmptyDataSourceMessage: true
          }}
          onRowClick={
            (event, rowData) => handleOpenCert(rowData)
          }
        />
      </GridItem>
    </GridContainer>
  </div>
}

export default CertificationsTable
