import React, { useContext } from 'react'

// import { Box, Menu, MenuItem, Typography, ListItemIcon } from '@material-ui/core'
import { Box } from '@material-ui/core'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import { makeStyles } from '@material-ui/core/styles'
// import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'

import Button from 'components/CustomButtons/Button.js'

const useStyles = makeStyles(presentationStyle)

const MenuMobile = () => {
  const { state, dispatch } = useContext(AppContext)
  const { sectionActive, company } = state

  // funzione che apre il menu mobile
  // const handleMenuOpen = event => {
  //   dispatch({
  //     type: 'SET_ANCHOR_MENU',
  //     payload: document.getElementById('mobile-menu-container')
  //   })
  // }

  // funzione che chiude il menu mobile
  const handleMenuClose = () => {
    dispatch({
      type: 'SET_ANCHOR_MENU',
      payload: null
    })
  }

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
    handleMenuClose()
  }

  const classes = useStyles()
  // const { t } = useTranslation('menu')

  return <Box id="mobile-menu-container" component="div" position={'fixed'} bottom={0} left={0} zIndex={'tooltip'} boxShadow={3} className={classes.menuContainerMobile}>
    <Box component="div" className={classes.mainMenuMobile}>
      <Button onClick={() => changeSection(null, 1)} className={classes.menuButtonMobilePadding}
        style={{
          backgroundColor: sectionActive !== 1
            ? 'transparent'
            : company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex
              : '#024289',

          color: sectionActive !== 1
            ? '#333'
            : 'white'
        }}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px' }} className="far fa-calendar-alt" />
          </div>
        </div>
      </Button>
      <Button onClick={() => changeSection(null, 5)} className={classes.menuButtonMobilePadding}
        style={{
          backgroundColor: sectionActive !== 5
            ? 'transparent'
            : company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex
              : '#024289',

          color: sectionActive !== 5
            ? '#333'
            : 'white'
        }}
      >
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-table" />
          </div>
        </div>
      </Button>
      <Button onClick={() => changeSection(null, 2)} className={classes.menuButtonMobilePadding}
        style={{
          backgroundColor: sectionActive !== 2
            ? 'transparent'
            : company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex
              : '#024289',

          color: sectionActive !== 2
            ? '#333'
            : 'white'
        }}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-industry" />
          </div>
        </div>
      </Button>
      {/* <Button onClick={() => changeSection(null, 3)} className={classes.menuButtonMobilePadding} color={sectionActive !== 3 ? 'transparent' : 'primary'}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-fingerprint" />
          </div>
        </div>
      </Button> */}
      {/* {state.product.showAllergenes &&
      <>
        <Button onClick={handleMenuOpen} className={classes.menuButtonMobilePadding} color={'transparent'}>
          <div className={classes.sectionButton}>
            <div style={{ marginBottom: '18px' }}>
              <i style={{ fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-ellipsis-v" />
            </div>
          </div>
        </Button>
        <Menu
          id="mobile-menu"
          anchorEl={anchorMenu}
          keepMounted
          open={Boolean(anchorMenu)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: '75vh',
              width: '90%'
            }
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <MenuItem onClick={() => changeSection(null, 4)}>
            <ListItemIcon><i style={{ fontSize: '1.4rem' }} className="fas fa-utensils" /></ListItemIcon>
            <Typography variant="inherit" noWrap>{t('allergenes')}</Typography>
          </MenuItem>

        </Menu>
      </>
      } */}
    </Box>
  </Box>
}

export default MenuMobile
