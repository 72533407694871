import React, { useContext } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { green, amber } from '@material-ui/core/colors'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Box, Tooltip, Fab, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { returnAbbrTwo, currLang } from '../../utils/translations.js'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DownLoadIcon from '@material-ui/icons/CloudDownload'
import CheckIcon from '@material-ui/icons/CheckCircle'
import TimeIcon from '@material-ui/icons/AccessTime'
import GetAppIcon from '@material-ui/icons/GetApp'
import { AppContext } from '../../context/AppState'
import GridItem from 'components/Grid/GridItem'
import configuration from '../../configuration'

const useStyles = makeStyles(presentationStyle)

// funzione che apre un doc
// const openDocument = (url) => {
//   const w = window.open('')
//   w.document.write(`<iframe width="100%" height="100%" src="${url}"></iframe>`)
//   w.document.write(`<html>
//       <head>
//         <title>ABC Abruzzo Blockchain| Documenti</title>
//       </head>
//       <body style="margin: 0px;">
//         <iframe width="100%" height="100%" src="${url}"></iframe>
//       </body>
//     </html>
//     `)
// }

const downloadDocument = (url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = 'ots document'
  a.click()
  window.URL.revokeObjectURL(url)
}

// funzione che apre un link
const openUrl = (url) => {
  window.open(url, '_blank')
}

const OpentimestampsHeader = (props) => {
  const { state } = useContext(AppContext)
  const classes = useStyles()
  const { t } = useTranslation('opentimestampsHeader')
  const { lot: currLot, lotNumber } = props
  const { company } = state

  return currLot.opentimestamps && currLot.opentimestamps.length > 0 &&
  <GridItem xs={12} md={12} lg={12} style={{ marginBottom: '70px', textAlign: 'center' }}>
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('status')}</TableCell>
            <TableCell align="center">{t('date')}</TableCell>
            <TableCell align="center">{t('txHash')}</TableCell>
            <TableCell align="right">{t('actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currLot.opentimestamps.map((timestamp, index) => (
            <TableRow key={index}>
              <TableCell scope="row">
                {timestamp.verified
                  ? <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <CheckIcon style={{ marginRight: '8px', color: green[700] }} />
                    <Typography variant="subtitle2">{t('successVerification')}</Typography>
                  </Box>
                  : <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                    <Typography variant="subtitle2">{t('pendingVerification')}</Typography>
                  </Box>
                }
              </TableCell>
              <TableCell align="center">
                {timestamp.date && timestamp.date !== ''
                  ? moment(timestamp.date).format('DD/MM/YYYY')
                  : moment(currLot.updatedAt).format('DD/MM/YYYY')
                }
              </TableCell>
              <TableCell align="center">
                {timestamp.transactionId && timestamp.transactionId !== ''
                  ? <>
                    <Link href ={`${configuration.blockchainExplorer}/${returnAbbrTwo(currLang)}/btc/tx/${timestamp.transactionId}`} target="_blank" color="inherit">
                      {timestamp.transactionId
                        .slice(0, 3)}...{timestamp.transactionId
                        .slice(61, 64)}`
                    </Link>
                  </>
                  : `${t('txHashNotPresent')}`
                }
              </TableCell>

              <TableCell align="right">
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <Tooltip title={t('downloadPDF')}>
                    <Fab
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '10px 5px',
                        color: company.metadata && company.metadata.subordinateColor && company.metadata.subordinateColor.hex ? company.metadata.subordinateColor.hex : '#FFF',
                        backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289'
                      }}
                      classes={{ primary: classes.fabPrimary }} disabled={!(currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].filename)} color="primary" size="small">
                      <Link
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '10px 5px',
                          color: company.metadata && company.metadata.subordinateColor && company.metadata.subordinateColor.hex ? company.metadata.subordinateColor.hex : '#FFF',
                          backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289'
                        }}
                        href={currLot.opentimestamps[index].filename}
                        target="_blank" color="inherit" >
                        <DownLoadIcon />
                      </Link>
                    </Fab>

                  </Tooltip>
                  <Tooltip title={t('downloadOTS')}>
                    <Fab
                      classes={{ primary: classes.fabPrimary }}
                      style={{
                        margin: '10px 5px',
                        color: company.metadata && company.metadata.subordinateColor && company.metadata.subordinateColor.hex ? company.metadata.subordinateColor.hex : '#FFF',
                        backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289'
                      }}
                      onClick={() => currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].ots ? downloadDocument(currLot.opentimestamps[index].ots) : {}}
                      color="primary" size="small">
                      <GetAppIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={t('moreInfo')}>
                    <Fab
                      classes={{ primary: classes.fabPrimary }}
                      style={{
                        margin: '10px 5px',
                        color: company.metadata && company.metadata.subordinateColor && company.metadata.subordinateColor.hex ? company.metadata.subordinateColor.hex : '#FFF',
                        backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289'
                      }}
                      onClick={() => currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].ots && lotNumber ? openUrl(`/opentimestamps/?filename=${currLot.opentimestamps[index].ots}&lotNum=${lotNumber}`) : {}}
                      color="primary"
                      size="small"
                    >
                      <ExitToAppIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </GridItem>
}

export default OpentimestampsHeader
