import React, { useContext } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import TraceabilityMobileCard from './TraceabilityMobileCard'
import TraceabilityCard from './TraceabilityCard'
import TraceabilityInput from './TraceabilityInput'
import OpentimestampsHeader from './OpentimestampsHeader'
import Product from 'components/Product/Product'

import { AppContext } from '../../context/AppState'

const useStyles = makeStyles(presentationStyle)

// funzione che ritorna l'altezza del contenitore delle card
// const calcCardHeight = (traceability) => {
//   let numTrackDoc = 0
//   traceability.forEach((track) => {
//     if (track.documents.length > 0) {
//       numTrackDoc = numTrackDoc + 1
//     }
//   })
//   const finalHeight = ((traceability.length * (traceability.length % 2 === 0 ? 700 : 800)) + (numTrackDoc * 30)) / (traceability.length > 1 ? 2 : 1)
//   return finalHeight
// }

const Traceability = () => {
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 960px)')
  const { t } = useTranslation('traceability')

  // stato globale dell'app estrapolato dal context
  const { state } = useContext(AppContext)
  const { urlHasLot, currLot, expirationDate, lotNumber } = state

  return <div style={isSmall ? { marginTop: '16px', paddingBottom: 0 } : { paddingBottom: urlHasLot ? 0 : '' }}>
    <Product showDescripton={false}/>
    {
    // Se c'è il lotto nell'url
      urlHasLot
        ? <GridContainer style={isSmall ? { padding: 0, marginTop: '16px' } : { padding: '80px 0 0 0', position: 'relative', zIndex: '90' }} spacing={4}>
          <GridItem xs={12} sm={12} md={3} lg={3}>
            <h3 className="sticky">{t('mainTitle')}</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={9}>
            <GridContainer justify="center" style={{ width: '100%' }}>
              <GridItem xs={12} style={{ marginBottom: currLot && currLot.opentimestamps && currLot.opentimestamps.length > 0 ? 30 : 70, textAlign: 'center' }}>
                <Typography style={{ marginBottom: '12px' }} variant="h4" component="h4">{`${t('title')} ${lotNumber || 1}`}</Typography>
                {expirationDate ? <Typography variant="body2" component="p">{`${t('expirationDate')}: `}<b>{moment(expirationDate).format('DD/MM/YYYY') || ''}</b></Typography> : ''}
              </GridItem>
              {currLot.status === 'active'
                ? <React.Fragment>
                  <OpentimestampsHeader lot={currLot} lotNumber={lotNumber} />
                  {isSmall
                    ? currLot.traceability.map((track, index) => {
                      return (track.showPhase
                        ? (
                          <TraceabilityMobileCard index={index} track={track} key={`${track.name}-${index}`} />
                        )
                        : null
                      )
                    })
                    : <Box component="div" style={{ maxWidth: '100%' }}>
                      {
                        currLot.traceability.map((track, index) => {
                          console.log('Track', track)
                          return (track.showPhase
                            ? (
                              <TraceabilityCard index={index} track={track} key={`${track.name}-${index}`} />
                            )
                            : null
                          )
                        }

                        )
                      }
                    </Box>}
                </React.Fragment>
              // se non è presente il lotto nell'url
                : <Box component="div" mb={4} boxShadow={1} className={classes.warningAlert}>
                  <Typography variant="button">{t('warning')}</Typography>
                  <Typography component="p" variant="body2">
                    {t('warningMessage', { lotNumber })}
                  </Typography>
                </Box>
              }
            </GridContainer>
          </GridItem>
        </GridContainer>
        : <TraceabilityInput />
    }
  </div>
}

export default Traceability
