require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  blockchainExplorer: 'https://www.blockchain.com',
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  sentry: {
    dsn: 'https://73a9c4a47fa84ac5bb07f9ea44c1d2ac@sentry.apio.cloud/42'
  }
}

export default configuration
