import React, { useEffect, useContext } from 'react'
import { AppContext } from '../../context/AppState'
const Line = () => {
  const { state } = useContext(AppContext)
  const { company } = state

  useEffect(() => {
    const line = document.getElementById('line')
    const length = line.getTotalLength()
    line.style.strokeDasharray = length
    line.style.strokeDashoffset = length
    window.addEventListener('scroll', () => {
      const scrollpercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight)

      const draw = length * scrollpercent
      line.style.strokeDashoffset = length - draw
    })
  }, [])
  return (
    <div className="lineContainer">
      <svg id="svgLine" width="145">
        <path
          fill="none"
          stroke={company.metadata && company.metadata.subordinateColor && company.metadata.subordinateColor.hex ? company.metadata.subordinateColor.hex : '#024289'}
          strokeWidth="1"
          id="line"
          d="M75 0 L75 200 L75 200 Z"/>
      </svg>
    </div>
  )
}
export default Line
