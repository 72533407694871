import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { Grid, Container } from '@material-ui/core'
import './style.css'

const TopBar = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { company } = state
  const { sectionActive } = state

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
  }

  const { t } = useTranslation('menu')
  return (
    <>
      <Grid container className="topBar"
        style={{ backgroundColor: company.metadata && company.metadata.mainColor ? company.metadata.mainColor.hex : '#024289' }}
      >
        <Container className="innerTopBar">
          <Grid item xs={12}>
            <div className="floatLeft textWhite bold">
              { company.metadata && company.metadata.logoPosition === 'center'
                ? <div className="logoMask">
                  <a href={company.website} target="_blank" rel="noopener noreferrer">
                    <img alt={company.name} className="logo" src={company.logo && company.logo.cropped}></img>
                  </a>
                </div>
                : <div className="logoMask left">
                  <a href={company.website} target="_blank" rel="noopener noreferrer">
                    <img alt={company.name} className="logo" src={company.logo && company.logo.cropped}></img>
                  </a>
                </div>
              }
            </div>
            <div className="navContainer">
              <span onClick={() => changeSection(null, 1)} color={sectionActive !== 1 ? 'transparent' : 'primary'}
                style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white' }}
              >
                <i style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white', fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-fingerprint" />
                {t('traceability')}
              </span>
              <span onClick={() => changeSection(null, 5)} color={sectionActive !== 5 ? 'transparent' : 'primary'}
                style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white' }}
              >
                <i style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white', fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-table" />
                {t('specifications')}
              </span>
              <span onClick={() => changeSection(null, 2)} color={sectionActive !== 2 ? 'transparent' : 'primary'}
                style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white' }}
              >
                <i style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white', fontSize: '1.4rem', marginRight: '2px' }} className="fas fa-industry" />
                {t('company')}
              </span>
              {state.product.certifications != null ? (state.product.certifications.length > 0 && (
                <span onClick={() => changeSection(null, 3)} color={sectionActive !== 3 ? 'transparent' : 'primary'}
                  style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white' }}
                >
                  <i style={{ color: company.metadata && company.metadata.subordinateColor ? company.metadata.subordinateColor.hex : 'white', fontSize: '1.4rem', marginRight: '2px' }} className="far fa-calendar-alt" />
                  {t('certifications')}
                </span>
              )) : null}
            </div>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

export default TopBar
