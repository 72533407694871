import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0'

import App from './App'
import * as serviceWorker from './serviceWorker'

import configuration from './configuration'

if (['production', 'test'].includes(configuration.environment)) {
  Sentry.init({ dsn: configuration.sentry.dsn, environment: configuration.environment })
  axios.get('https://json.geoiplookup.io').then(response => {
    const networkInfo = response.data
    Sentry.configureScope(scope => {
      scope.setUser({ ip_address: networkInfo.ip })
      Object.keys(networkInfo)
        .filter(key => !['asn', 'continent_code', 'country_code', 'currency_code', 'ip', 'org', 'success'].includes(key))
        .forEach(key => {
          scope.setExtra(key, networkInfo[key])
        })
    })
  }).catch(() => {})
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
