export default (state, action) => {
  switch (action.type) {
    case 'SET_PRODUCT': {
      return {
        ...state,
        product: action.payload
      }
    }
    case 'SET_COMPANY': {
      return {
        ...state,
        company: action.payload
      }
    }
    case 'SET_LOT': {
      return {
        ...state,
        currLot: action.payload
      }
    }
    case 'SET_EXPIRATION_DATE': {
      return {
        ...state,
        expirationDate: action.payload
      }
    }
    case 'SET_URL_HAS_LOT': {
      return {
        ...state,
        urlHasLot: action.payload
      }
    }
    case 'SET_MENU_SECTION': {
      return {
        ...state,
        sectionActive: action.payload
      }
    }
    case 'SET_ANCHOR_MENU': {
      return {
        ...state,
        anchorMenu: action.payload
      }
    }
    case 'SET_OPEN_CERT': {
      return {
        ...state,
        openCert: action.payload
      }
    }
    case 'SET_CERT': {
      return {
        ...state,
        selectedCert: action.payload
      }
    }
    case 'SET_OPEN_TRACK_DOC': {
      return {
        ...state,
        openTrackDoc: action.payload
      }
    }
    case 'SET_TRACK_DOC': {
      return {
        ...state,
        selectedTrackDoc: action.payload
      }
    }
    case 'SET_OPEN_TRACK_DESC': {
      return {
        ...state,
        openTrackDesc: action.payload
      }
    }
    case 'SET_TRACK_DESC': {
      return {
        ...state,
        selectedTrackDesc: action.payload
      }
    }
    case 'SET_LOT_NOT_FOUND': {
      return {
        ...state,
        lotNotFound: action.payload
      }
    }
    case 'SET_LOT_NUMBER': {
      return {
        ...state,
        lotNumber: action.payload
      }
    }
    case 'SET_INFO_FOUND': {
      return {
        ...state,
        informationFound: action.payload
      }
    }
    case 'SET_COMPANY_LOADED': {
      return {
        ...state,
        companyLoaded: action.payload
      }
    }
    default:
      return state
  }
}
