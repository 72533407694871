import React, { useEffect, useContext } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppContext } from '../../context/AppState'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import NotFound from 'components/NotFound/NotFound'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Box, Container, Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { getProduct, getLots, populateLotData } from '../../actions/AppActions'
import Fade from 'react-reveal/Fade'
import './style.css'

const useStyles = makeStyles(presentationStyle)

// main hook
export default function Product (props) {
  // React-router hook per prendere i parametri passati nell'url (uuid o gtin prodotto, uuid o lotNumber del lotto)
  const { prod, lot } = useParams()
  // stato globale dell'app e funzione che fa il dispatch delle azioni
  const { state, dispatch } = useContext(AppContext)

  const { sectionActive, product, informationFound } = state
  const { company } = state

  // funzione che controlla il link al social e se manca l'http lo aggiunge
  const checkHttpInSocialLinks = (socialLinks) => {
    Object.keys(socialLinks).forEach(social => {
      if (socialLinks[social] && (!socialLinks[social].startsWith('http://') && !socialLinks[social].startsWith('https://'))) {
        socialLinks[social] = `http://${socialLinks[social]}`
      }
    })
    return socialLinks
  }

  // funzione che scarica i dati del prodotto scansionato e della sua azienda produttrice
  async function fetchProducts () {
    try {
      const product = await getProduct(prod)

      if (product && product.social) {
        const { website, ...social } = checkHttpInSocialLinks({ ...product.social, website: product.website })
        product.social = social
        product.website = website
      }

      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
    } catch (e) {
      dispatch({
        type: 'SET_INFO_FOUND',
        payload: false
      })
    }
  }

  // funzione che cambia lingua in base all'elemento selezionato
  // const selectLanguage = (label) => {
  //   switch (label) {
  //     case 'ITA': {
  //       return changeLanguage('it-IT')
  //     }
  //     case 'ENG': {
  //       return changeLanguage('en-US')
  //     }
  //     default: {
  //       return changeLanguage('it-IT')
  //     }
  //   }
  // }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLots () {
    try {
      let lots = await getLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        const lotNum = lots.lotNumber
        dispatch({
          type: 'SET_EXPIRATION_DATE',
          payload: expirationDate
        })
        dispatch({
          type: 'SET_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lotNum
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // Custom hook che fa il fetch dei dati in base ai parametri ricevuti nell'url
  const useFetchData = () => {
    useEffect(() => {
      if (!lot) {
        fetchProducts()
      } else {
        fetchProducts()
        fetchLots()
      }
    }, [])
  }

  const parseHtml = () => {
    if (product && product.description) {
      try {
        return parse(product.description)
      } catch {
        return ''
      }
    } else {
      return ''
    }
  }

  // chiamata all'hook che fa il fetch dei dati
  useFetchData()

  // definisco stili e media query
  const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')

  const { t } = useTranslation('title')

  return !informationFound
    ? <NotFound />
    : (
      <SkeletonTheme color="#ddebdd" highlightColor="#d3e3d3">
        <div style={{ backgroundColor: isSmall ? '#fafafa' : '' }}>
          <Container className="mainHome">
            <Grid container spacing={5} alignItems='center'>

              <Grid item xs={12} sm={12} md={4} lg={3} style={{ position: 'realtive', zIndex: '1' }}>
                {
                  product && product.profileImage && product.profileImage.cropped
                    ? <img alt={product.name} className="productImg" src={product.profileImage.cropped} />
                    : <Grid item sm={12} lg={3} className={classes.flexCenter}>
                      <Box display={isSmall && sectionActive !== 1 ? 'none' : 'block'} className={classNames(classes.imgContainer)} style={{ minWidth: '200px', width: '200px' }}>
                        <Skeleton duration={0.7} width={200} height={200} circle={true} />
                      </Box>
                    </Grid>
                }
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={9} className="content" style={{ position: 'realtive', zIndex: '1' }}>
                <Fade big>
                  <Box component="div">
                    {props.showDescripton
                      ? <>
                        <h4
                          className={`${classes.mainCardTitle} productTitle`}
                          style={{ paddingTop: isSmall && sectionActive !== 1 ? 64 : 0, color: company.metadata && company.metadata.titleColor ? company.metadata.titleColor.hex : '#024289' }}
                        >{product && product.name}</h4>

                        <h3 className="productSubtitle" style={{ marginTop: '0' }}><b>{t('product')}</b></h3>
                        <p>{parseHtml()}</p>
                      </>
                      : <h4
                        className={`${classes.mainCardTitle} productTitle`}
                        style={{ textAlign: 'center', paddingTop: isSmall && sectionActive !== 1 ? 64 : 0, color: company.metadata && company.metadata.titleColor ? company.metadata.titleColor.hex : '#024289' }}
                      >{product && product.name}</h4>
                    }
                  </Box>
                </Fade>
              </Grid>
            </Grid>
          </Container>
        </div>

      </SkeletonTheme>
    )
}
